<template>
  <common-page-shell container-name="empty">
    <router-view />
  </common-page-shell>
</template>

<script>
import CommonPageShell from './CommonPageShell.vue';

export default {
  name: 'Empty',
  layout: 'Empty',
  components: {
    CommonPageShell,
  },
};
</script>
